.Wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .Alternative {
        margin-top: 30px;
    }
    .ContactUs {
        margin-top: 10px;
    }
}