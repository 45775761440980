.Wrapper {
    line-height: 18px;
    font-size: 14px;
    color: var(--gray-dark);
    height: var(--panel-header-h);
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid var(--gray-f2);
    width: 100%;
    padding-inline: var(--panel-header-padding-x);
    flex-shrink: 0;
    .Item {
        padding: var(--menu-item-padding-y) var(--menu-item-padding-x);
        display: flex;
        cursor: pointer;
        align-items: center;
        gap: calc(1 * var(--menu-item-padding-x));
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        // width: 100%;
        background: none;
        border: none;
        border-radius: 5px;
        &:hover {
            background-color: var(--gray-f3);
        }
    }
    .ItemActive {
        background-color: var(--gray-f3);
    }
}