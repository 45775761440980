.Container {
    display: flex;
    align-items: baseline;
    margin-bottom: 4px;
    .Label {
        font-size: 12px;
        min-width: 30px;
        color: var(--gray-72);
    }
    .Content {
        width: 100%;
    }
}