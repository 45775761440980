.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    gap: 15px;
    .Header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px 20px;
        padding: 5px;
        flex-wrap: wrap;
        max-width: 100vw;
    }
    .Info {
        text-align: center;
        color: var(--gray-dark-text);
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }
}

.LoginForm {
    width: 318px;
    max-width: 80vw;
}

.LoginRequest {
    margin: 30px auto;
    text-align: center;
    color: var(--gray-dark);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}