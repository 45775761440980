.LoadingRow {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    height: 18px;
    >div:first-child {
        animation: loading 1s infinite alternate;
        animation-delay: 0s;
    }
    >div:nth-child(2) {
        animation: loading 1s infinite alternate;
        animation-delay: 0.5s;
    }
    >div:last-child {
        animation: loading 1s infinite alternate;
        animation-delay: 1s;
    }
}

@keyframes loading {
    0% {
        background-image: linear-gradient( to right, var(--primary), var(--primary-dark));
    }
    50%,
    100% {
        background-color: var(--gray-middle);
    }
}