.IconWrapper {
    display: flex;
    align-items: center;
    border-radius: 50%;
    &--bordered {
        border: 1px solid var(--gray-dark-text);
    }
    .Icon {
        rect,
        line {
            stroke: var(--gray-dark-text);
        }
        path {
            fill: var(--gray-dark-text);
        }
    }
    &--selected .Icon {
        rect,
        line {
            stroke: var(--primary-dark) !important;
        }
        path {
            fill: var(--primary-dark) !important;
        }
    }
    &:not(.NoHover):hover {
        // background-color: var(--primary-dark);
        cursor: pointer;
        .Icon {
            rect,
            line {
                stroke: var(--primary-dark);
            }
            path {
                fill: var(--primary-dark);
            }
        }
    }
    &--selected:hover .Icon {
        rect,
        line {
            stroke: var(--primary-dark) !important;
        }
        path {
            fill: var(--primary-dark) !important;
        }
    }
    &--spin {
        animation: spin-animation 1.5s infinite;
    }
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}