.Background {
    background: rgba(100, 100, 100, 0.5);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: fixed;
    top: -100px;
    left: -100px;
    z-index: 4000;
    transition: 0s;
    display: flex;
    opacity: 0.1;
    align-items: center;
    justify-content: center;
    &--open {
        opacity: 0.9;
        transform: none;
        position: fixed;
        border-radius: 0px;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
    }
}

.Modal {
    position: fixed;
    background: #ffffff;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    top: 50%;
    left: 50%;
    z-index: 5000;
    transition: 0.4s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    max-width: 0;
    max-height: 0;
    overflow: hidden;
    &--open {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        overflow-y: auto;
        max-height: 100vh;
        max-width: 100%;
    }
}