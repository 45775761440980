.Menu {
    width: 100%;
    height: 100vh;
    background: var(--chat-gray-bg);
    border-right: 1px solid var(--chat-gray-border);
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding: 0 var(--menu-padding-x);
}

.MenuHeader {
    height: calc( var(--menu-header-content-h) + 2 * var(--menu-header-padding-y));
    display: flex;
    align-items: center;
    padding: var(--menu-header-padding-y) var(--menu-header-padding-x);
    gap: 10px;
    .Avatar {
        width: var(--menu-header-content-h);
        height: var(--menu-header-content-h);
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        >svg {
            width: var(--menu-header-content-h);
            height: var(--menu-header-content-h);
        }
    }
    .UserName {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.MenuListItem {
    padding: var(--menu-item-padding-y) var(--menu-item-padding-x);
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: calc(2 * var(--menu-item-padding-x));
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    background: none;
    border: none;
    border-radius: 5px;
    &:hover,
    &--isActive {
        background-color: var(--gray-f3);
    }
}

.MenuDivider {
    height: 1px;
    width: 100%;
    background-color: var(--chat-gray-border);
    margin: var(--menu-divider-margin-y) 0;
}

.MenuFooter {
    margin: auto var(--menu-footer-margin-x) var(--menu-footer-margin-bottom);
}