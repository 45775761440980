.Tile {
    border: 1px solid var(--gray-a9);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 12px 18px;
    flex-direction: column;
    min-height: 236px;
    .Title {
        font-size: 14px;
        color: var(--font-color);
        margin-bottom: 8px;
    }
    .Subtitle {
        font-size: 14px;
        color: var(--gray-69);
    }
    .Lower {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .Price {
            font-size: 14px;
            color: var(--font-color);
            font-weight: 500;
        }
        .Button {
            height: fit-content;
        }
    }
}