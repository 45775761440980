.Wrapper {
    padding-bottom: 20vh;
}

.ConnectForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 320px;
    .Input {
        width: 100%;
    }
    form {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }
}

button {
    color: var(--font-color);
}