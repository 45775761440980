.Wrapper {
    width: 100%;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    .TopLine {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        font-size: 12px;
        .FromAndCount {
            display: flex;
            gap: 2px;
            .Count {
                flex-shrink: 0;
                color: var(--gray-72);
            }
            .Email {
                color: var(--gray-8f);
                margin-left: 12px;
            }
        }
        .Time {
            color: var(--gray-72);
            flex-shrink: 0;
            display: flex;
            align-items: center;
            gap: 8px;
            path {
                fill: var(--gray-72)
            }
        }
    }
    .Subject,
    .Snippet {
        display: inline-grid;
    }
    .Snippet {
        .Ellipsis {
            color: var(--gray-72);
        }
    }
}

.Ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}