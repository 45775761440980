.Form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Title {
    margin-bottom: 20px;
}

.LinesWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .FormLine {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        position: relative;
    }
}

.CloseIcon {
    position: absolute;
    right: -28px
}

.ButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px auto;
}

.Input,
.Select {
    flex-basis: 50%;
}

.Input {
    min-height: 48px;
}

.Select {
    [class*="-control"] {
        border: 1px solid var(--gray-a9);
        border-radius: 5px;
        min-height: 48px;
    }
    [class*="-placeholder"] {
        color: var(--gray-22);
        font-size: 14px;
    }
    &Error [class*="-control"] {
        border-color: red;
        outline: none;
    }
}

.Textarea {
    min-height: 44px;
    color: var(--gray-22);
    font-size: 14px;
    padding: 11px 17px;
    border: 1px solid var(--gray-a9);
    border-radius: 5px;
    width: 100%;
    flex-grow: 1;
    &:disabled {
        border-color: transparent;
        background: none;
    }
    &Error {
        border-color: red;
        outline: none;
    }
    &Processing {
        background-color: var(--gray-f5) !important;
        border: 1px solid var(--gray-a9) !important;
    }
}