.Wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 6px;
    cursor: pointer;
    &--active,
    &:hover {
        background-color: var(--gray-f3);
    }
    .Info {
        width: 12px;
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-direction: column;
        gap: 5px;
    }
    .Details {
        width: calc(100% - 48px);
    }
    .DrawerMenuWrapper {
        position: absolute;
        right: 100%;
        padding: 8px;
        .DrawerMenu {
            border-radius: 5px;
            background-color: white;
            padding: 4px;
            display: flex;
            justify-content: center;
            gap: 8px;
            .DrawerItem {
                background-color: var(--gray-f3);
                border-radius: 4px;
                padding: 2px;
            }
        }
    }
}

.Ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.Conversation {
    width: 13px;
    height: 13px;
    border-radius: 50vh;
    border: 2px solid var(--primary);
    &WithMessages {
        background-color: var(--primary);
    }
}