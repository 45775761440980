.WIP {
  display: flex;
  justify-content: space-around;
  padding: 33px 0;
  // animation: elevate 20s infinite alternate;
}

.WIPHummer {
  animation: wip-hummer 1s infinite alternate;
}

.WIPSaw {
  animation: wip-saw 1s infinite alternate;
}

@keyframes wip-hummer {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes wip-saw {
  0% {
    transform: rotate(15deg) translateX(0);
  }
  50% {
    transform: rotate(25deg) translateX(-5px);
  }
  100% {
    transform: rotate(35deg) translateX(5px);
  }
}

@keyframes elevate {
  0% {
    margin-top: 0;
  }
  25% {
    margin-top: 40vh;
  }
  50% {
    margin-top: 80vh;
  }
  75% {
    margin-top: 40vh;
  }
  100% {
    margin-top: 0;
  }
}
