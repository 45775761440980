.GetHelp {
    margin-top: 80px;
    text-align: center;
    color: var(--gray-69);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    .FirstText {
        display: block;
    }
}