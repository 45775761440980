.Title {
    margin-bottom: 20px;
}

.NamesWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .NameWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        position: relative;
    }
}

.CloseIcon {
    position: absolute;
    right: -28px
}

.ButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px auto;
}