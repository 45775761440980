.ComposeContainer {
    display: flex;
    gap: 20px;
    flex-direction: column;
    .InfoContainer {
        width: 100%;
        .SubjectWrapper {
            width: 100%;
            .Subject {
                height: auto;
                border-radius: 50vh;
            }
        }
    }
    .MessageCompose {
        .EditableMessage {
            min-height: 85px;
            outline: none;
            font-size: 12px;
        }
        .IconThreeDots {
            background-color: var(--gray-f3);
            border-radius: 5px;
            width: 24px !important;
            padding: 5px;
            cursor: pointer;
            path {
                fill: var(--gray-72);
            }
            &:hover {
                background-color: var(--gray-e4);
            }
        }
    }
    .Attachments {
        position: relative;
        .Title {
            font-size: 12px;
            color: var(--gray-72);
            margin-bottom: 9px;
        }
        .Container {
            display: flex;
            flex-direction: column;
            gap: 3px;
        }
        .AttachmentTag {
            &:hover {
                text-decoration: underline;
                cursor: default;
            }
        }
        .AttachmentContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 450px;
            background-color: var(--gray-f5);
            padding: 5px 15px;
            .AttachmentText {
                font-size: 12px;
                color: #15c;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .Actions {
        display: flex;
        align-items: center;
        gap: 9px;
        .SaveBtn {
            background: var(--green-gradient);
            border-color: transparent;
            color: var(--font-color);
        }
        .ActionIcon {
            padding: 5px;
            width: 24px !important;
            height: 24px !important;
            flex-shrink: 1;
            &:hover {
                background-color: var(--green);
            }
        }
        .UploadInput {
            display: none;
        }
    }
}