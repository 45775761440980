.Tags {
    display: flex;
    column-gap: 12px;
    flex-wrap: wrap;
    .InputWrapper {
        flex: 1 0 200px;
        margin: 0;
        min-width: 200px;
    }
    .Input {
        height: auto;
        border-radius: 50vh;
        min-width: 250px;
        width: auto;
    }
}