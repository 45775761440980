@import "styles/variables.scss";
.ChatBubbleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  .When {
    margin: 30px 0 52px 0;
    color: var(--chat-bubble-when);
  }
  .Who {
    color: var(--chat-bubble-who);
  }
  .ChatBubble {
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    width: 100%;
    gap: 8px;
    margin-bottom: 22px;
    .AvatarPlaceholder {
      flex: 0 0 var(--chat-avatar-size);
    }
    .MessageContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
      .Who {
        padding: 7px 11px;
      }
      .Message {
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-radius: 16px;
        padding: 7px 11px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        min-height: var(--chat-avatar-size);
        background-color: var(--chat-bubble-bg);
        border: none;
      }
    }
  }
  &.MessageType-sent {
    .MessageContainer {
      align-content: flex-end;
      .Who {
        align-self: flex-end;
      }
    }
  }
  &.ChatType-client.MessageType-sent {
    .Message {
      background-image: linear-gradient(
        90deg,
        var(--primary),
        var(--primary-dark)
      );
      color: var(--white);
    }
  }
  &.ChatType-paraplanner.MessageType-sent {
    .Message {
      background-image: linear-gradient(
        90deg,
        var(--green),
        var(--green-light)
      );
    }
  }
}
