$gap: 20px;
.Title {
    font-size: 14px;
    color: var(--gray-69);
}

.TileContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: $gap;
    margin-top: 40px;
}

.Tile {
    flex: 0 0 calc(33.33% - 2/3 * #{$gap});
}

@media (max-width: 1200px) {
    .Tile {
        flex: 0 0 calc(50% - 1/2 * #{$gap});
    }
}

@media (max-width: 800px) {
    .Tile {
        flex: 0 0 100%;
    }
}