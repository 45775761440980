.Input {
    height: 44px;
    border: 1px solid var(--gray);
    border-radius: 5px;
    width: 100%;
    padding: 0 17px;
    outline: none;
    &:active,
    &:focus {
        border-color: var(--primary);
    }
    &--error {
        border-color: var(--red);
    }
}

.InputWrapper {
    position: relative;
    &--formed {
        margin: 10px 0 20px 0;
    }
}

.Error {
    position: absolute;
    color: var(--red);
    font-size: 11px;
    font-weight: 400;
    margin-top: -1px;
}

.InputMinimalist {
    min-height: 44px;
    color: var(--gray-22);
    font-size: 14px;
    padding: 11px 17px;
    border: 1px solid var(--gray-a9);
    border-radius: 5px;
    min-width: 0;
    flex-grow: 1;
    &:disabled {
        border-color: transparent;
        background: none;
    }
    &Error {
        border-color: red;
        outline: none;
    }
    &Processing {
        background-color: var(--gray-f5) !important;
        border: 1px solid var(--gray-a9) !important;
    }
}