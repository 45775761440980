@font-face {
    font-family: "GeistSans";
    src: url("../fonts/Geist-1.3.0/Geist-Thin.woff2");
    src: url("../fonts/Geist-1.3.0/Geist-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "GeistSans";
    src: url("../fonts/Geist-1.3.0/Geist-UltraLight.woff2");
    src: url("../fonts/Geist-1.3.0/Geist-UltraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "GeistSans";
    src: url("../fonts/Geist-1.3.0/Geist-Light.woff2");
    src: url("../fonts/Geist-1.3.0/Geist-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "GeistSans";
    src: url("../fonts/Geist-1.3.0/Geist-Regular.woff2");
    src: url("../fonts/Geist-1.3.0/Geist-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "GeistSans";
    src: url("../fonts/Geist-1.3.0/Geist-Medium.woff2");
    src: url("../fonts/Geist-1.3.0/Geist-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "GeistSans";
    src: url("../fonts/Geist-1.3.0/Geist-SemiBold.woff2");
    src: url("../fonts/Geist-1.3.0/Geist-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "GeistSans";
    src: url("../fonts/Geist-1.3.0/Geist-Bold.woff2");
    src: url("../fonts/Geist-1.3.0/Geist-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "GeistSans";
    src: url("../fonts/Geist-1.3.0/Geist-Black.woff2");
    src: url("../fonts/Geist-1.3.0/Geist-Black.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "GeistSans";
    src: url("../fonts/Geist-1.3.0/Geist-UltraBlack.woff2");
    src: url("../fonts/Geist-1.3.0/Geist-UltraBlack.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}