.Wrapper {
    position: relative;
}

.ListContent {
    padding-top: 55px;
}

.LineMarker {
    margin: 20px 0;
}

.LoaderRef {
    margin-top: 25px;
    font-size: 1px;
}

.SearchWrapper {
    position: fixed;
    width: 346px;
    background-color: white;
    background-color: white;
    z-index: 2;
    padding-bottom: 10px;
    padding-top: 10px;
    top: var(--panel-header-h);
    .Search {
        border-radius: 50vh;
        padding: 0 32px;
    }
    .Close {
        position: absolute;
        top: 24px;
        left: calc(100% - 28px);
    }
}