.Wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    .Column {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .Left {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 30px;
        .Video {
            display: flex;
            justify-content: center;
        }
    }
    .Right {
        flex: 1;
    }
    @media (max-width: 1000px) {
        flex-direction: column;
    }
}

.VideoClassName {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;
}

.VideoIframeClassName {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}