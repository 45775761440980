.Wrapper {
  box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.03);
  position: relative;
  width: 100%;
  display: flex;
  border: 1px solid #e4e4e4;
  border-radius: 8px;

  &:has(> textarea:active),
  &:has(> textarea:focus) {
    border-color: var(--primary);
  }
}

.Textarea {
  width: 100%;
  resize: none;
  outline: none;
  padding: 14px 16px 0;
  margin-bottom: 45px;
  font-weight: 400;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  max-height: calc(100vh - var(--header-height) - 120px);
  line-height: 21px;
  height: 35px;
}

.ControlArea {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;

  > button {
    border: none;
    background: none;
    padding: 0;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
