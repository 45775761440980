.Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .Menu {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        gap: 8px;
        .Item {
            cursor: pointer;
            display: flex;
            align-items: center;
            color: var(--gray-72);
            min-height: 30px;
            border-radius: 15px;
            padding: 0 8px;
            &--active,
            &:hover {
                background-color: var(--gray-f3);
            }
        }
    }
    .Actions {
        display: flex;
        align-items: center;
        gap: 12px;
        .ActionIcon {
            cursor: pointer;
            padding: 5px;
            width: 26px !important;
            height: 26px !important;
            flex-shrink: 1;
            &:hover {
                background-color: var(--gray-f3);
            }
        }
        .BrainIcon {
            cursor: pointer;
            padding: 5px;
            width: 36px !important;
            height: 36px !important;
            flex-shrink: 1;
            &:hover {
                background-color: var(--gray-f3);
            }
            &HasUnread {
                background-color: var(--primary) !important;
                background: linear-gradient(-45deg, var(--primary), var(--primary), var(--green), var(--green));
                animation: gradient 3s ease infinite;
                background-size: 400% 400%;
            }
        }
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}