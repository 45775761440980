.ModelWrapper {
    display: flex;
    flex-direction: column;
    width: var(--portfolio-model-width);
    gap: 10px;
    position: relative;
    .Icon {
        path {
            fill: var(--gray-a9);
        }
    }
    .IconAdd {
        margin-top: 14px;
        margin-right: 28px;
    }
    .TitleWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        .Title {
            flex-grow: 1;
        }
        .Title input:disabled {
            font-weight: bold;
        }
    }
    .ItemWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }
    .AddWrapper {
        display: flex;
        justify-content: center;
    }
}

.Input {
    min-height: 44px;
    color: var(--gray-22);
    font-size: 14px;
    padding: 11px 17px;
    border: 1px solid var(--gray-a9);
    border-radius: 5px;
    min-width: 0;
    &:disabled {
        border-color: transparent;
        background: none;
    }
    &Error {
        border-color: red;
        outline: none;
    }
    &Processing {
        background-color: var(--gray-f5) !important;
        border: 1px solid var(--gray-a9) !important;
    }
}

.Hidden {
    visibility: hidden;
}

.Error {
    position: absolute;
    color: var(--red);
    font-size: 12px;
    font-weight: 400;
    bottom: 17px;
}

.DisplayValue {
    min-height: 44px;
    color: var(--gray-22);
    font-size: 14px;
    padding: 11px 17px;
    flex-basis: 184px;
}

.Uppercase {
    text-transform: uppercase;
}

.Bold {
    font-weight: 700;
}