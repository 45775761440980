.Button {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    background-image: linear-gradient(90deg, var(--green), var(--green-light)) !important;
    border-radius: 5px;
    border: none;
    height: 44px;
    width: 100%;
    cursor: pointer;
    &:disabled {
        background: #f3f3f3;
        color: #c5c5c5;
    }
    &--loading {
        background: #f3f3f3;
    }
    &--secondary {
        background-image: var(--primary-gradient) !important;
        color: white !important;
    }
    &--ternary {
        color: var(--gray-72);
        font-weight: 400;
        border: 1px solid var(--gray-72);
        background-image: none;
        background-color: white;
    }
    &--outline {
        color: var(--gray-22);
        font-weight: 400;
        border: 1px solid var(--gray-22);
        background-image: none;
        background-color: white;
        border-radius: 50vh;
        width: auto;
        padding: 5px 16px;
        height: auto;
        line-height: 18px;
        min-width: 110px;
        &:hover {
            color: var(--gray-72);
            border: 1px solid var(--gray-72);
        }
    }
}