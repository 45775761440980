.Box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 18px;
    border: 1px solid var(--gray-a9);
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    .Title {
        color: var(--gray-22);
    }
    .Content {
        color: var(--gray-69);
    }
    &:hover {
        background-color: var(--gray-f3);
    }
}