@import "styles/variables.scss";

.AvatarContainer {
  position: relative;
  flex-shrink: 0;
  .AvatarWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: transparent;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      display: block;
    }
  }
  .AvatarLetter {
    // position: relative;
    // top: 1px;
  }
  &.NoImage {
    .AvatarWrapper {
      background-color: var(--avatar-grey);
      color: var(--white);
      text-align: center;
    }
  }
}
