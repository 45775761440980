.LineMarker {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--gray-72);
    line-height: 0.1em;
    &Text {
        color: var(--gray-72);
        background: #fff;
        padding: 0 10px;
        font-size: 12px;
    }
}