$automations-margin: 40px;
.BackBtnContainer {
    position: absolute;
    display: inline-block;
}

.BackBtn {
    width: 34px !important;
    height: 34px !important;
    padding: 5px;
    justify-content: center;
    border: 1px solid var(--gray-dark-text);
    svg {
        width: 24px;
        height: 24px;
        position: relative;
        left: -2px;
    }
    &:hover {
        border-color: var(--primary-dark);
    }
}

.Wrapper {
    display: flex;
    .Stepper {
        display: flex;
        flex-direction: column;
        width: 100%;
        .Content {
            display: flex;
            flex-direction: column;
            gap: 10px;
            color: var(--gray-69);
        }
        .StepsContainer {
            display: flex;
            justify-content: space-between;
            padding: 18px;
            padding-top: 20px;
            .Step {
                width: 22px;
                height: 22px;
                background-color: var(--gray-f3);
                border-radius: 50vh;
                &Active {
                    background-color: var(--primary);
                }
            }
        }
    }
    &--automations {
        .Stepper {
            margin: #{$automations-margin} auto 20px;
            width: 80%;
            min-height: calc(100vh - 2 * var(--header-height) - 3 * #{$automations-margin});
            justify-content: center;
        }
    }
}