:root {
    --font-color: var(--gray-22);
    --green: #14d60a;
    --green-light: #9ff433;
    --green-gradient: linear-gradient(90deg, var(--green), var(--green-light));
    --primary: #cc0ad6;
    --primary-dark: #660ad6;
    --primary-gradient: linear-gradient(90deg, var(--primary), var(--primary-dark));
    --gray-light: #d2d2d2;
    --gray: #e4e4e4;
    --chat-gray-bg: #fbfbfc;
    --chat-gray-border: var(--gray-f2);
    --field-gray: var(--gray-f3);
    --gray-66: #666666;
    --gray-69: #696969;
    --gray-22: #222222;
    --gray-f2: #f2f2f2;
    --gray-f3: #f3f3f3;
    --gray-f5: #f5f5f5;
    --gray-e4: #e4e4e4;
    --gray-8f: #8f8f8f;
    --gray-72: #727272;
    --gray-middle: #c5c5c5;
    --gray-a9: #a9a9a9;
    --gray-dark-text: var(--gray-69);
    --gray-dark: #727373;
    --red: #ea5228;
    --white: #ffffff;
    --header-height: 60px;
    --chat-avatar-size: 36px;
    --chat-bubble-bg: #f3f3f3;
    --chat-bubble-when: var(--gray-a9);
    --chat-bubble-who: #a4a4a4;
    --avatar-grey: #c4c4c4;
    // --toastify-color-progress-light: linear-gradient( to right, var(--primary), var(--primary-dark));
    // --toastify-color-error: var(--red);
    // --toastify-color-success: var(--green-light);
    // --toastify-icon-color-success: var(--green);
    // --toastify-icon-color-error: var(--toastify-color-error);
    // --toastify-color-progress-success: linear-gradient( to right, var(--green), var(--green-light));
    // --toastify-color-progress-error: var(--toastify-color-error);
    // Spacing
    --spacing-2: 0.125; // 4px
    --spacing-4: 0.25rem; // 4px
    --spacing-8: 0.5rem; // 8px
    --spacing-12: 0.75rem; // 12px
    --spacing-16: 1rem; // 16px
    --spacing-20: 1.25rem; // 20px
    --spacing-24: 1.5rem; // 24px
    --spacing-32: 2rem; // 32px
    --spacing-48: 3rem; // 48px
    --spacing-96: 6rem; // 96px
    --spacing-112: 7rem; // 112px
    --spacing-160: 10rem; // 160px
    // Menu
    --menu-width: 246px;
    --menu-padding-x: 12px;
    --menu-header-padding-x: 8px;
    --menu-header-padding-y: 17px;
    --menu-header-content-h: 26px;
    --menu-item-padding-x: 8px;
    --menu-item-padding-y: 8px;
    --menu-divider-margin-y: 8px;
    --menu-footer-margin-bottom: 16px;
    --menu-footer-margin-x: 8px;
    --panel-header-h: 60px;
    --panel-header-padding-x: 12px;
    --panel-body-padding-x: 12px;
    --panel-body-padding-top: 12px;
    --feedback-padding: 8px;
    --portfolio-model-width: 350px;
}