.Wrapper {
    position: relative;
    width: 100%;
}

.Checkbox {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    color: var(--gray-69);
    &__Input {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        cursor: pointer;
        border: 1px solid red !important;
        border-radius: 4px;
        background: transparent;
    }
    &__Error {
        border: 1px solid red !important;
    }
}

.Error {
    position: absolute;
    color: var(--red);
    font-size: 11px;
    font-weight: 400;
    margin-top: -1px;
}