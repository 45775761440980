.Textarea {
    min-height: 44px;
    color: var(--gray-22);
    font-size: 14px;
    padding: 11px 17px;
    border: 1px solid var(--gray);
    border-radius: 5px;
    min-width: 0;
    flex-grow: 1;
    width: 100%;
    margin: 10px 0 20px 0;
    &Error {
        border-color: red;
        outline: none;
    }
    &:disabled,
    &Processing {
        background-color: var(--gray-f5) !important;
        border: 1px solid var(--gray-a9) !important;
    }
}