.Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    gap: 15px;
    flex-direction: column;
    .Title {
        font-size: 14px;
        color: var(--gray-69);
        text-align: center;
    }
    .Buttons {
        width: var(--portfolio-model-width);
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding-right: 28px;
    }
}