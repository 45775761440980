.Wrapper {
    overflow-y: auto;
}

.Title {
    color: var(--gray-66);
    font-size: 12px;
    padding: var(--spacing-8);
    padding-bottom: var(--spacing-12);
}

.Item {
    display: flex;
    align-items: center;
    gap: 11px;
    padding: var(--spacing-8) var(--spacing-8);
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 2px;
    &:hover,
    &--selected {
        background-color: var(--gray-f3);
    }
    .AvatarWrapper {
        border-radius: 4px;
        background-color: var(--gray-8f) !important;
    }
    &Info {
        display: flex;
        flex-direction: column;
        flex: 0 0 calc(100% - 41px);
        overflow: hidden;
        &__email {
            color: var(--gray-8f);
        }
        &__email,
        &__name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}