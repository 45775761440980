.DataList {
    &Item {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }
    &Icon {
        width: 25px;
        display: flex;
        align-items: center;
        height: 30px;
    }
    &Key {
        display: flex;
        align-items: center;
        flex: 0 1 90px;
        color: var(--gray-dark-text);
        min-height: 30px;
    }
    &Value {
        flex: 1;
        position: relative;
        &Input {
            border: 1px solid white;
            &:hover {
                background: var(--field-gray);
                border: 1px solid var(--field-gray);
            }
            &--initial {
                border: 1px solid var(--gray);
                background: transparent;
                border-radius: 4px;
                &:hover {
                    background: transparent;
                }
            }
            &--touched {
                border: 1px solid var(--primary);
                border-bottom: none;
                border-radius: 4px 4px 0 0;
                &:hover {
                    background: transparent;
                    border: 1px solid var(--primary);
                    border-bottom: none;
                }
            }
            padding: 4px;
            border-radius: 4px;
            align-items: center;
            position: relative;
            >input {
                margin: 0;
                padding: 0;
                background: transparent;
                border: none;
                outline: none;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            &--has-copy-button {
                >input {
                    padding-right: 25px;
                }
            }
        }
        &Confirmation {
            position: absolute;
            min-height: 30px;
            width: 100%;
            text-align: left;
            padding: 0 4px;
            background: white;
            border: 1px solid var(--primary);
            border-radius: 0 0 4px 4px;
            cursor: pointer;
            z-index: 2;
            word-wrap: break-word;
        }
    }
}

.Copy {
    position: absolute;
    border: none;
    background: none;
    right: 8px;
    top: calc(50%);
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        >svg {
            fill: red;
        }
    }
}