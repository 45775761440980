.AutomationDescription {
    color: var(--gray-69);
    div {
        color: var(--gray-69);
    }
    // text-align: center;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
}