.Feedback {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    overflow-wrap: break-word;
    margin: 0px;
    padding: var(--feedback-padding);
    border-radius: var(--feedback-padding);
    background-color: #e6f4ff;
    border: 1px solid #91caff;
    &--success {
        background-color: #f6ffed;
        border: 1px solid #b7eb8f;
    }
    &--warning {
        background-color: #fffbe6;
        border: 1px solid #ffe58f;
    }
    &--error {
        background-color: #fff2f0;
        border: 1px solid #ffccc7;
    }
}