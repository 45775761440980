.MessageContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
    .MessageInfo {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 2px;
    }
    .MessageBody {
        position: relative;
        font-size: 12px;
    }
    .IconThreeDots {
        background-color: var(--gray-f3);
        border-radius: 5px;
        width: 24px !important;
        padding: 5px;
        cursor: pointer;
        path {
            fill: var(--gray-72);
        }
        &:hover {
            background-color: var(--gray-e4);
        }
    }
    .Attachments {
        position: relative;
        .Title {
            font-size: 12px;
            color: var(--gray-72);
            margin-bottom: 9px;
        }
        .AttachedFile {
            display: flex;
            align-items: center;
            gap: 14px;
            font-size: 12px;
            color: var(--gray-8f);
            path {
                fill: var(--gray-72)
            }
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
    .Actions {
        margin-bottom: 20px;
    }
    .ComposeWrapper {
        border-top: 1px solid var(--gray-f2);
        padding-top: 20px;
        .ComposeContainer {
            border: 1px solid var(--gray-f2);
            border-radius: 8px;
            padding: 20px;
        }
    }
}

.MessageDivider {
    border: none;
    height: 1px;
    background-color: var(--gray-f2);
    margin: 0 20px;
}

.LoadingThreadDetails {
    height: 100%;
}