.dropzone {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 110px !important;
    box-sizing: border-box;
    transition: all 0.15s linear;
    &NoMoreDrop {
        min-height: initial !important;
    }
}

.inputLabel,
.inputLabelWithFiles {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    border: 1px solid #a9a9a9;
    border-radius: 5px;
    background-color: var(--gray-f3);
    &:hover {
        background-color: var(--gray-e4);
        svg {
            path {
                fill: var(--primary-dark);
            }
        }
    }
}

.preview {
    // padding: 0px 1px;
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    // min-height: 60px;
    z-index: 1;
    // border-bottom: 1px solid #ECECEC;
    box-sizing: border-box;
    [class*="previewFileName"] {
        font-family: "Poppins", sans-serif;
        color: var(--gray-69);
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    progress {
        accent-color: var(--green);
    }
}

.PreviewContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Layout {
    display: flex;
    flex-direction: column;
    gap: 10px;
}