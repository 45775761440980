$user-avatar-size: 26px;

.Wrapper {
  width: $user-avatar-size;
  height: $user-avatar-size;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  > svg {
    width: $user-avatar-size;
    height: $user-avatar-size;
  }
}

.AvatarMock {
  background-color: #c4c4c4;
  width: $user-avatar-size;
  height: $user-avatar-size;
}
