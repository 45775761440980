.PanelHeader {
    line-height: 18px;
    font-size: 14px;
    color: var(--gray-dark);
    height: var(--panel-header-h);
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--gray-f2);
    width: 100%;
    padding-inline: var(--panel-header-padding-x);
    flex-shrink: 0;
}