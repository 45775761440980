.Modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 372px;
    .Content {
        width: 100%;
    }
    .FormTitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        align-self: flex-start;
    }
    .Buttons {
        display: flex;
        gap: 20px;
        flex-direction: row;
        .CloseBtn {
            color: var(--gray-72);
            font-weight: 400;
            border: 1px solid var(--gray-72);
            background-image: none;
            background-color: white;
        }
    }
}