.PageContainer {
    display: flex;
    gap: 20px;
    .Panel {
        overflow: auto;
        // border: 1px solid blue;
        height: calc(100vh - var(--panel-header-h) - 30px);
        &PDF {
            flex-basis: 710px;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
        }
    }
}

.Row {
    display: flex;
    align-items: center;
}

.AddBtn {
    margin: 20px auto;
}