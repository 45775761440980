.Wrapper {
  position: relative;
  cursor: pointer;
}

.LinkText {
  background: linear-gradient(90deg, var(--primary), var(--primary-dark));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Underline {
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  bottom: 2px;
  background: linear-gradient(90deg, var(--primary), var(--primary-dark));
}
