.Tag {
    display: flex;
    align-items: center;
    gap: 6px;
    // box-shadow: 0 0 0 1px var(--gray) inset;
    // border-radius: 50vh;
    font-size: 12px;
    color: var(--gray-8f);
    line-height: 18px;
}

.Icon {
    path {
        fill: var(--gray-8f) !important;
    }
}