.Wrapper {
  height: 60px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  gap: 16px;

  &:hover {
    background: var(--field-gray);
  }
}

.Message {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.TopRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -3px;
}

.Sender {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.SendTime {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--gray-dark);
}

.Preview {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--gray-dark);
}
