.Wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 1;
  max-height: calc(100vh - var(--header-height));
}

.MessagesArea {
  padding: 24px 32px 12px;
  overflow-y: auto;
  overflow-x: hidden;
}

.TextAreaWrapper {
  padding: 0 32px 24px;
}
